/*
These functions make sure WordPress
and Foundation play nice together.
*/


//change the path the video. The .mp4, .webm, .ogv will be added by the function
  function lazy_background_video(vidsrc){
    //if($('body#body').hasClass('home') && !$('body#body').hasClass('device-mobile')){
    if(!$('body#body').hasClass('device-mobile')){
      //var $vidsrc = vidsrc;
      var $video = $('<video id="bgvid" autoplay loop style="opacity:0;display:none;"></video>');
      $video.append(
        '<source src="'+vidsrc+'.mp4" type="video/mp4" />'
        +'<source src="'+vidsrc+'.webm" type="video/webm" />'
        +'<source src="'+vidsrc+'.ogv" type="video/ogg" />'
      );
      $('body#body #page-header').append($video);
      $video.get(0).oncanplay = function() {
        $video.css({'display':'block'}).animate({'opacity':1},500);
      };
      $('body#body .geg-page-content').css({'background-image':'none','background-color':'transparent'});
    }
  }
//  lazy_background_video(vid);

jQuery(function($){

  function ajax_hours(){
    var date_stamp = new Date().getTime();
    jQuery.ajax({
       type : "post",
       dataType : "json",
       url : 'http://www.thegoodearthgarden.com/blog/wp-content/themes/goodearth-sass/assets/functions/hours.php?stamp='+date_stamp,
       data : {'action':'hours_of_operation'},
       success: function(response) {
          $('.company-hours').html(response.shortcode_text);
          $('#head-band .company-hours').html(response.header_text);
       }
    }); 
  }
  ajax_hours();

  function backstretch_init(){
    $.each(window.goodearth.backstretch,function(index,slideshow){
      $(slideshow.id).backstretch(slideshow.slides);
    });
  }

  function backstretch_resize(){
    $.each(window.goodearth.backstretch,function(index,slideshow){
      $(slideshow.id).backstretch("resize");
    });
  }

  function equalizer_refresh(){
    $.each($('[data-equalizer]'),function(index,element){
      var elem = new Foundation.Equalizer($(element)); 
      elem.applyHeight();
    });
  }

  function mix_it_up(){
    if($('#sort-by-tag').length > 0){
      var product_squares = $('.product-squares');
      if( product_squares.length > 0 ){
        product_squares.find('.item').addClass('mix');
        product_squares.mixItUp({
          selectors: {
              target: '.mix',
              filter: '.tag-filter'
          },
          load: {
            filter: '.tag-all'
          }
          // for later: https://mixitup.kunkalabs.com/learn/tutorial/advanced-filtering/#multi-dimensional-filtering
        });
      }
    }
  }

  window.scrollingToHash = false;
  function scroll_to_hash(hash){
    if(!window.scrollingToHash){
      window.scrollingToHash = true;
      nav_stick_check();
      var target = $(hash),
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      var target_offset = target.offset().top,
      scroll_y = window.pageYOffset || document.documentElement.scrollTop;
      $('body').addClass('nav-shrink');
      setTimeout(function(){
        var nav_height = $('#main-nav').outerHeight() + $('#head-band').outerHeight() + $('#wpadminbar').outerHeight();
        $('html,body').animate({
          scrollTop: (target_offset - nav_height)
        }, 800,function(){
          nav_stick_check();
          window.scrollingToHash = false;
        });
      },250);
    }
  }


  $(document).ready(function() {

     // Makes sure last grid item floats left
    $('.columns').last().addClass( 'end' );

    // Adds Flex Video to YouTube and Vimeo Embeds
    $('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
      if ( $(this).innerWidth() / $(this).innerHeight() > 1.5 ) {
        $(this).wrap("<div class='widescreen flex-video'/>");
      } else {
        $(this).wrap("<div class='flex-video'/>");
      }
    });

  });

  window.onload = function(){
    setTimeout(function(){
      $(document).foundation();
      $.each($('img[data-src]'),function(index,element){
        $(this).attr('src',$(this).attr('data-src')).removeClass('lazy-hidden').addClass('lazy-loaded');
      });
      backstretch_init();
      mix_it_up();
    },50);
    if(window.location.hash) {
      scroll_to_hash(window.location.hash);
    }
  }
  

  var windowWidth = $(window).width();
  window.resizing = false;
  $(window).on('resize',function(e){
    if(window.resizing == false && $(window).width() != windowWidth){
      window.resizing = true;
      windowWidth = $(window).width();
      setTimeout(function(){
        equalizer_refresh();
        backstretch_resize();
        window.resizing=false;
        if( Foundation.MediaQuery.atLeast('large') && $('body').hasClass('mobile-nav-open') ){
          $('body').removeClass('mobile-nav-open');
        }
      },100);
    }
  });

  $(document).on('click','#switch',function(e){
    var _=$('#body'), nav_class = 'mobile-nav-open';
    if(_.hasClass(nav_class)){
      $('html').css('overflow','auto');
      _.removeClass(nav_class);
    }else{
      $('html').css('overflow','hidden');
      _.addClass(nav_class);
    }
  });

  $(document).on('click','#toggle-hidden-blog-cats',function(e){
    e.preventDefault();
    $cats = $('#blog-categories #cat-list');
    $button = $(this);
    if($cats.hasClass('hidden')){
      $cats.removeClass('hidden');
      $button.text('Show Less Topics');
    }else{
      $cats.addClass('hidden');
      $button.text('Show More Topics');
      var nav_height = $('#main-nav').outerHeight() + $('#head-band').outerHeight(),
      target_offset = $('#blog-categories').offset().top;
      window.scrollTo( 0, (target_offset - nav_height) );
    }
  });

  function nav_stick_check(){
    var scroll_y = window.pageYOffset || document.documentElement.scrollTop;
    if ( scroll_y > 24 && Foundation.MediaQuery.atLeast('large') ) {
      $('body').addClass('nav-shrink');
    }
    else {
      $('body').removeClass('nav-shrink');
    }
  }
  nav_stick_check();

  $(window).scroll(function(){
    nav_stick_check();
  });

$(function() {
  $('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      scroll_to_hash(target);
      return false;
    }
  });
});

}); //end jquery no conflict
